<!--设备运维：模板列表：模板明细-->
<template>
  <div class="app-container page-container">
    <div class="section">
      <!--页头-->
      <div class="page-header">
        <div class="ph-left">
          <!--页面标题-->
          <h2 class="page-title">{{ $t('Maintenance_template') }}</h2>
        </div>
        <div class="ph-right">
          <!--返回按钮-->
          <el-button type="" size="small" icon="el-icon-back" @click="back">
            {{ $t('return') }}
          </el-button>
        </div>
      </div>
      <!--按钮组-->
      <div v-if="!entity.disab && managePermission" class="control-box mb-40">
        <div class="cb-left">
          <!--添加按钮-->
          <el-button
            v-if="saveSign"
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="addTemplateDetail"
          >
            {{ $t('add') }}
          </el-button>
          <!--修改按钮-->
          <el-button
            v-if="!saveSign"
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="modifyTemplate"
          >
            {{ $t('Revise') }}
          </el-button>
          <!--保存按钮-->
          <el-button
            v-if="saveSign"
            :loading="saveBtnLoading"
            type="primary"
            size="small"
            @click="saveTemplate"
          >
            {{ $t('device_save') }}
          </el-button>
        </div>
      </div>
      <!--表单-->
      <div class="mb-40">
        <!--标题-->
        <div class="form-header">
          <div class="box-title">{{ $t('Template_Information') }}</div>
        </div>
        <!--表单-->
        <div class="form-box">
          <el-form
            ref="entityForm"
            :model="entity"
            :rules="rules"
            label-position="left"
            label-width="140px"
          >
            <!--模板名称-->
            <el-form-item :label="$t('device_template_name')" prop="name">
              <el-input
                v-model.trim="entity.name"
                clearable
                :disabled="disabLabel"
                :placeholder="$t('device_template_name')"
              />
            </el-form-item>
            <!--印刷类型-->
            <el-form-item :label="$t('device_type')" prop="deviceType">
              <el-cascader
                ref="myCascader"
                v-model="entity.typeIds"
                :placeholder="$t('please_select_device_type')"
                :disabled="disabLabel"
                :options="typeList"
                :props="{ expandTrigger: 'hover' }"
                style="width: 100%"
                size="medium"
                @change="handleChange"
              />
            </el-form-item>
            <!--          <el-form-item :label="$t('device_print_type')" prop="printType">-->
            <!--            <el-select v-model="entity.printType" style="width: 100%" :placeholder="$t('device_print_type')" :disabled="disabLabel" value=""  @change="setDeviceType">-->
            <!--              <el-option-->
            <!--                v-for="item in opts.printTypes"-->
            <!--                :key="item.id"-->
            <!--                :label="item.name"-->
            <!--                :value="item.id"-->
            <!--              />-->
            <!--            </el-select>-->
            <!--          </el-form-item>-->
            <!--          &lt;!&ndash;设备类型&ndash;&gt;-->
            <el-form-item :label="$t('maintenance_type')" prop="type">
              <el-select
                v-model="entity.type"
                style="width: 100%"
                :placeholder="$t('select_maintenance_type')"
                :disabled="disabLabel"
              >
                <el-option
                  v-for="item in maintainTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!--标题-->
      <div class="form-header">
        <div class="box-title">{{ $t('detailed_information') }}</div>
      </div>
      <!--表格-->
      <div class="table-box">
        <el-table
          v-loading="listLoading"
          :data="detailList"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
        >
          <!--序号-->
          <el-table-column align="center" :label="$t('no')" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('position')" align="center">
            <template slot-scope="scope">
              {{ scope.row.part }}
            </template>
          </el-table-column>

          <el-table-column align="center" :label="$t('component')">
            <template slot-scope="scope">
              {{ scope.row.element }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('work')" align="center">
            <template slot-scope="scope">
              {{ scope.row.work }}
            </template>
          </el-table-column>

          <el-table-column label="SOP" align="center">
            <template slot-scope="scope">
              {{ scope.row.sop }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('maintenance_standard')" align="center">
            <template slot-scope="scope">
              {{ scope.row.standard }}
            </template>
          </el-table-column>

          <el-table-column v-if="!entity.disab && saveSign" :label="$t('caozuo')" align="center" width="220">
            <template slot-scope="scope">
              <el-button
                  type="primary"
                v-if="managePermission"
                size="mini"
                icon="el-icon-edit"
                @click="modifyTemplateDetail(scope.$index, scope.row)"
              >
                {{ $t('Revise') }}
              </el-button>

              <el-button
                v-if="managePermission"
                type="primary"
                plain
                size="mini"
                icon="el-icon-delete"
                @click="deleteTemplateDetail(scope.$index)"
              >
                {{ $t('delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!--弹出框-->
    <el-dialog
      v-if="showModifyDetailDialog"
      :visible.sync="showModifyDetailDialog"
      title=""
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="closeModifyDetailDialog"
    >
      <el-form
        ref="detailForm"
        :model="modifyEntity"
        :rules="detailRules"
        label-position="left"
        label-width="110px"
      >
        <el-form-item :label="$t('position')" prop="part">
          <el-input v-model.trim="modifyEntity.part" clearable :placeholder="$t('position')" size="medium" />
        </el-form-item>

        <el-form-item :label="$t('component')" prop="element">
          <el-input v-model.trim="modifyEntity.element" clearable :placeholder="$t('component')" size="medium" />
        </el-form-item>

        <el-form-item :label="$t('work')" prop="work">
          <el-select style="width: 100%;" v-model="modifyEntity.work" :placeholder="$t('work')" size="medium">
            <el-option
                v-for="item in workList"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="SOP" prop="sop">
          <el-input
            v-model.trim="modifyEntity.sop"
            type="textarea"
            :rows="2"
            clearable
            placeholder="SOP"
            size="medium"
          />
        </el-form-item>

        <el-form-item :label="$t('maintenance_standard')" prop="standard">
          <el-input
            v-model.trim="modifyEntity.standard"
            type="textarea"
            :rows="2"
            clearable
            :placeholder="$t('maintenance_standard')"
            size="medium"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="modifyConfirmBtnLoading" @click="modifyConfirmBtn">
          {{ $t('device_confirm') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// { getDeviceTemplateDetail, getDeviceConfigList, updateTemplate, createTemplate }
import API from '@/api';
import valid from '@/utils/validator';
// import { mapGetters } from 'vuex'
import { manageOauth } from '@/utils/validate';
import convert from '@/utils/convert';

export default {
  data() {
    return {
      buttons: '',
      flag: '',
      saveSign: false,
      disabLabel: true,
      listLoading: false,
      saveBtnLoading: false,
      modifyConfirmBtnLoading: false,
      detailList: [],
      typeList: [],
      workList: [
        {value:this.$t('lubrication'),label: this.$t('lubrication')},
        {value:this.$t('inspection_cleaning'),label: this.$t('inspection_cleaning')},
        {value:this.$t('adjustment'),label: this.$t('adjustment')},
      ],
      entity: {
        printType: '',
        deviceType: '',
        name: '',
        typeIds: [],
        type: null,
        disab: true,
      },
      modifyEntity: {},
      templateId: this.$route.query.id,
      isEdit: this.$route.query.isEdit, // 是否可以编辑
      // opts: {
      //   deviceTypes: [],
      //   printTypes: []
      // },
      maintainTypes: [
        { name: this.$t('day_maintainance'), id: '1' },
        { name: this.$t('weeks_maintainance'), id: '2' },
        { name: this.$t('half_months_maintainance'), id: '30' },
        { name: this.$t('months_maintainance'), id: '3' },
        { name: this.$t('quarter_maintainance'), id: '4' },
        { name: this.$t('semi_annual_maintenance'), id: '5' },
        { name: this.$t('annual_maintenance'), id: '6' },
      ],
      showModifyDetailDialog: false,
      rules: {
        name: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              valid.checkNull(rule, value, callback, this.$t('enter_template_name'), false);
              valid.checkByRegex(rule, value, callback, /^.{1,30}$/, this.$t('enter_template_name_characters'));
            },
          },
        ],
        printType: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, '请选择印刷类型');
            },
          },
        ],
        type: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, this.$t('select_maintenance_type'));
            },
          },
        ],
        deviceType: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              valid.checkSelect(rule, value, callback, this.$t('please_select_device_type'));
            },
          },
        ],
      },
      detailRules: {
        part: {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            valid.checkNull(rule, value, callback, this.$t('enter_part_content'), false);
            valid.checkByRegex(rule, value, callback, /^.{2,50}$/, this.$t('content_characters'));
          },
        },
        element: {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            valid.checkNull(rule, value, callback, this.$t('component_content'), false);
            valid.checkByRegex(rule, value, callback, /^.{2,50}$/, this.$t('component_content_words'));
          },
        },
        work: {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            valid.checkNull(rule, value, callback, this.$t('job_content'), false);
            valid.checkByRegex(rule, value, callback, /^.{2,50}$/, this.$t('words_work_content'));
          },
        },
        sop: {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            valid.checkNull(rule, value, callback, this.$t('SOP_content'), false);
            valid.checkByRegex(rule, value, callback, /^.{2,200}$/, this.$t('content_of_words'));
          },
        },
        standard: {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            valid.checkNull(rule, value, callback, this.$t('maintenance_standard_content'), false);
            valid.checkByRegex(rule, value, callback, /^.{2,200}$/, this.$t('standard_content_words'));
          },
        },
      },
    };
  },
  computed: {
    managePermission: {
      get() {
        return manageOauth('device_operate', this.buttons);
      },
    },
  },
  created() {
    if (this.$store.getters.userInfo.buttons) {
      this.buttons = this.$store.getters.userInfo.buttons;
    } else {
      this.buttons = sessionStorage.getItem('buttons');
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('buttons', JSON.stringify(this.$store.getters.userInfo.buttons));
    });
    // 编辑
    if (this.isEdit) {
      this.modifyTemplate();
    }
  },
  mounted() {
    // 获取设备类型
    API.getDeviceConfigList()
      .then((response) => {
        this.typeList = convert.arr2cascader_deviceconfig(response.message.data);
        if (this.templateId !== 'NEW') {
          this.getDetails();
        } else {
          this.entity.disab = false;
          this.saveSign = true;
          this.disabLabel = false;
        }
      })
      .catch((err) => {
        this.$message({
          message: err,
          type: 'error',
        });
      });
    // this.getPrintTypes()
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getDetails() {
      API.getDeviceTemplateDetail({ templateId: this.templateId }).then((response) => {
        this.entity.printType = response.message.data.printType;
        this.entity.deviceType = response.message.data.deviceType;
        this.entity.name = response.message.data.name;
        this.entity.type = response.message.data.type;
        this.entity.typeIds = [];
        this.entity.typeIds.push(this.entity.printType);
        this.entity.typeIds.push(this.entity.deviceType);
        const disab = response.message.data.createPerson;
        // '0'是系统模板
        if (disab !== '0') {
          this.entity.disab = false;
        } else {
          this.entity.disab = true;
        }
        this.detailList = response.message.data.templateDetail;
        // this.getDeviceTypes(this.entity.printType)
      });
    },
    // getPrintTypes() {
    //   getPrintType().then(response => {
    //     this.opts.printTypes = response.data
    //   })
    // },
    // getDeviceTypes(printTypeId) {
    //   getDeviceType({ 'printTypeId': printTypeId }).then(response => {
    //     this.opts.deviceTypes = response.data
    //   })
    // },
    // setDeviceType(printTypeId) {
    //   this.getDeviceTypes(printTypeId)
    //   this.entity.deviceType = ''
    // },
    modifyTemplateDetail(index, val) {
      this.flag = 'modify';
      this.modifyEntity = JSON.parse(JSON.stringify(val));
      this.modifyEntity.index = index;
      this.showModifyDetailDialog = true;
    },
    closeModifyDetailDialog() {
      this.showModifyDetailDialog = false;
    },
    modifyConfirmBtn() {
      this.$refs.detailForm.validate((v) => {
        if (v) {
          this.modifyConfirmBtnLoading = true;
          if (this.flag === 'modify') {
            this.detailList.splice(this.modifyEntity.index, 1, this.modifyEntity);
            this.modifyConfirmBtnLoading = false;
          }
          if (this.flag === 'add') {
            if (this.modifyEntity) {
              this.detailList.push(this.modifyEntity);
            }
            this.modifyConfirmBtnLoading = false;
          }
          this.showModifyDetailDialog = false;
        } else {
          return false;
        }
        return v;
      });
    },
    deleteTemplateDetail(val) {
      this.$confirm(this.$t('template_details_continue'), this.$t('caozuo'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm') ,
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
        .then(() => {
          this.detailList.splice(val, 1);
        })
        .catch(() => {});
    },
    saveTemplate() {
      this.$refs.entityForm.validate((v) => {
        if (v) {
          if (this.detailList.length === 0) {
            return this.$message.error(this.$t('Please_add_more_details'));
          }
          const params = {
            printType: this.entity.printType,
            deviceType: this.entity.deviceType,
            name: this.entity.name,
            templateId: this.templateId,
            templateDetail: this.detailList,
            type: this.entity.type,
          };
          this.saveBtnLoading = true;
          if (this.templateId === 'NEW') {
            // 添加模板
            API.createTemplate(params)
              .then((response) => {
                if (response.message.success) {
                  this.saveBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    showClose: true,
                    type: 'success',
                  });
                  this.$router.push({ name: 'templateList' });
                } else {
                  this.saveBtnLoading = false;
                  this.$message({
                    message: response.message.message,
                    showClose: true,
                    type: 'error',
                  });
                }
              })
              .catch(() => {
                this.saveBtnLoading = false;
              });
          } else {
            // 修改模板
            API.updateTemplate(params)
              .then((response) => {
                this.$message({
                  message: response.message.message,
                  showClose: true,
                  type: response.message.success ? 'success' : 'error',
                });
                this.saveBtnLoading = false;
                if (response.message.success) {
                  this.$router.go(-1);
                  // this.disabLabel = true;
                  // this.saveSign = false;
                }
              })
              .catch(() => {
                this.saveBtnLoading = false;
              });
          }
        } else {
          return false;
        }
        return v;
      });
    },
    addTemplateDetail() {
      this.flag = 'add';
      this.modifyEntity = {};
      this.showModifyDetailDialog = true;
    },
    modifyTemplate() {
      this.saveSign = true;
      this.disabLabel = false;
    },
    handleClose(done) {
      this.$confirm(this.$t('Confirm_Close'))
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleChange() {
      this.entity.printType = this.entity.typeIds[0];
      this.entity.deviceType = this.entity.typeIds[1];
    },
  },
};
</script>
